import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import { Product, CommodityGroup, PriceList } from '../../../products/models';
import { PaginatedResponse, Location } from '../../../shared/models';

import { RequestHeaders, getProductsByPage, getPriceLists, getCommodityGroups } from '../../Api';
import { getProductPriceForPos, getProductPriceByPriceListId } from '../../../products/lib/price';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core/common';
import KoronaPosInstance from '../../services/KoronaPosService/KoronaPosService';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
// import { SamplePriceLists } from './sampleData';
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		grow: {
			flexGrow: 1,
		},
		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			borderColor: theme.palette.primary.main,
			borderWidth: 1,
			border: 'solid',
			backgroundColor: theme.palette.common.white,
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(2),
				marginRight: theme.spacing(2),
				width: '100%',
			},
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between'
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			color: theme.palette.primary.main,
		},
		inputRoot: {
			color: theme.palette.primary.main,
			width: '100%'
		},
		inputInput: {
			padding: 0,
			paddingLeft: `1em`,
			transition: theme.transitions.create('width'),
			width: '100%',
			fontSize: '1.5rem',
			[theme.breakpoints.up('md')]: {
				width: '100%'
			},
			"&::placeholder": {
				color: "#757575"
			},
		},
		header: {
			fontSize: '1.2rem',
			backgroundColor: '#eee',
			width: '100%',
			display: 'flex',
			outline: 0
		},
		row: {
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			outline: 0,
			borderBottom: '1px solid rgba(224, 224, 224, 1)',
			'&:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0.04)'
			},
			cursor: 'pointer'
		},
		headerCell: {
			padding: '10px 14px',
			fontSize: '0.95rem',
			textAlign: 'left',
			fontWeight: 500,
			lineHeight: 1.43,
			letterSpacing: '0.01071em',
			verticalAlign: 'middle'
		},
		cell: {
			padding: '10px 14px',
			fontSize: '1rem',
			textAlign: 'left',
			fontWeight: 400,
			lineHeight: 1.43,
			letterSpacing: '0.01071em',
			verticalAlign: 'middle',
			textOverflow: 'ellipsis',
			overflow: 'hidden'
		},
		spacing: {
			paddingLeft: 16,
			paddingRight: 16
		},
		spacingRight: {
			paddingRight: 16
		},
		spacingLeft: {
			paddingLeft: 16
		},
		label: {
			fontWeight: 500,
			fontSize: '1.1rem'
		}
	}),
);
const DefaultFilters = [
	{
		id: 1,
		name: 'in_stock',
		label: 'In Stock',
		activeConstraint: '&in_stock=True',
		inActiveConstraint: '',
		active: true
	},
	{
		id: 2,
		name: 'location',
		label: 'Location',
		activeConstraint: '&location_ids=',
		inActiveConstraint: '',
		active: true
	},
];

const PageData = {
	count: 0,
	results: [],
	next: null,
	previous: null
} as PaginatedResponse<Product>;



const ProductListScreen = (props: any) => {
	let defaultFilters = DefaultFilters.map(a => ({ ...a }));
	const classes = useStyles();
	const [isLoading, setLoading] = useState(false);
	const [queryText, setQueryText] = useState('');
	const [pageData, setPageData] = useState(PageData);
	const [isFilterPanelVisible, setFilterPanelVisible] = useState(false);
	const [filters, setFilters] = useState(defaultFilters.map(a => ({ ...a })));
	const [categories, setCategories] = useState([] as CommodityGroup[]);
	const [priceLists, setPriceLists] = useState([] as PriceList[]);
	const [productsInit, setProductsInit] = useState(false);

	const navigate = (product: Product) => {
		props.history.push(props.history.location.pathname + '/detail', product);
	};
	/* filters
		1. in stock: true
		2. location
				at this location (default): 
				at any location
	*/
	const getProducts = (authHeaders: RequestHeaders, businessId: number, queryText: string | undefined) => {
		setLoading(true);
		const filters = getFilterString();
		getProductsByPage(authHeaders, 1, 10, null, businessId, queryText, filters)
			.catch(handleError) // handle network issues
			.then(checkStatus)
			.then(parseJSON)
			.then((data: PaginatedResponse<Product>) => {
				setPageData(data);
				setLoading(false);
				if (!productsInit) {
					setProductsInit(true);
				}
			})
			.catch((error: any) => {
				setLoading(false);
			});
	};

	const getCategories = (authHeaders: RequestHeaders, businessId: number, commodityGroupIds: string[]) => {
		setLoading(true);
		let filters = '&ids=' + commodityGroupIds;
		getCommodityGroups(authHeaders, null, businessId, filters)
			.catch(handleError) // handle network issues
			.then(checkStatus)
			.then(parseJSON)
			.then((data: CommodityGroup[]) => {
				if (data && data.length > 0) {
					const allCategories = data.map(c => {
						c['selected'] = false;
						return c;
					});
					setCategories(allCategories);
				}
				setLoading(false);
			})
			.catch((error: any) => {
				setLoading(false);
			});
	};

	const getPriceGroups = (authHeaders: RequestHeaders, businessId: number, priceGroupIds: string[]) => {
		setLoading(true);
		let filters = '&ids=' + priceGroupIds;
		getPriceLists(authHeaders, null, businessId, filters)
			.catch(handleError) // handle network issues
			.then(checkStatus)
			.then(parseJSON)
			.then((data: PriceList[]) => {
				if (data && data.length > 0) {
					const { defaultPriceListId } = props.authData.settings;
					let allPrices = data.map(c => {
						c.selected = false;
						if (c.uuid === defaultPriceListId) {
							c.selected = true;
						}
						return c;
					});
					allPrices = sortBy(allPrices, 'name');
					setPriceLists(allPrices);
				}
				setLoading(false);
			})
			.catch((error: any) => {
				setLoading(false);
			});
	};

	useEffect(() => {
		const { business, settings, authHeaders } = props.authData;
		if (business && settings && authHeaders) {
			const { selectedCommodityGroupIds, selectedPriceListIds } = settings;
			if (selectedPriceListIds && selectedPriceListIds.length > 0) {
				getPriceGroups(authHeaders, business.id, selectedPriceListIds);
			}
			if (selectedCommodityGroupIds && selectedCommodityGroupIds.length > 0) {
				getCategories(authHeaders, business.id, selectedCommodityGroupIds);
			} else {
				getProducts(authHeaders, business.id, queryText);
			}
		}
	}, []);

	useEffect(() => {
		if (queryText.length > 2) {
			const { business, authHeaders } = props.authData;
			const timeoutId = setTimeout(() => getProducts(authHeaders, business.id, queryText), 300);
			return () => clearTimeout(timeoutId);
		}
	}, [queryText]);

	useEffect(() => {
		if (productsInit) {
			handleSearch();
		}
	}, [filters]);

	useEffect(() => {
		if (categories.length > 0) {
			handleSearch();
		}
	}, [categories]);

	const handleSearch = () => {
		const { business, authHeaders } = props.authData;
		if (business && authHeaders) {
			getProducts(authHeaders, business.id, queryText);
		}
	};

	const clearSearch = () => {
		if (queryText.length > 0) {
			setQueryText('');
			const { business, authHeaders } = props.authData;
			if (business && authHeaders) {
				getProducts(authHeaders, business.id, '');
			}
		}
	};

	const getFilterString = () => {
		let filterstring = '';
		filters.forEach(f => {
			if (f.active) {
				if (f.name === 'location') {
					const { locations, settings } = props.authData;
					if (locations && settings) {
						const { selectedLocationId } = settings;
						if (selectedLocationId) {
							const location = locations.find((l: Location) => l.id === selectedLocationId);
							filterstring += f.activeConstraint + location.korona_org_unit;
						}
					}
				} else {
					filterstring += f.activeConstraint;
				}
			} else {
				filterstring += f.inActiveConstraint;
			}
		});
		if (categories && categories.length > 0) {
			const selectedCategoryIds = categories.filter(c => c['selected']).map(c => c.uuid);
			if (selectedCategoryIds.length > 0) {
				filterstring += '&commodityGroup_id__in=' + selectedCategoryIds;
			}
		}
		return filterstring;
	};

	const toggleCategory = (c: CommodityGroup) => {
		const allCategories = [...categories];
		for (let cat of allCategories) {
			if (cat.uuid === c.uuid) {
				cat['selected'] = !cat['selected'];
				break;
			}
		}
		setCategories(allCategories);
	};

	const togglePriceList = (c: PriceList) => {
		const allPrices = [...priceLists];
		for (let i of allPrices) {
			if (i.uuid === c.uuid) {
				i['selected'] = !i['selected'];
				break;
			}
		}
		setPriceLists(allPrices);
	};

	const showFilters = () => {
		setFilterPanelVisible(!isFilterPanelVisible);
	};

	const resetFilters = () => {
		// clear commodity group selection
		resetCommodityGroups();
		// clear pricelist selection
		resetPriceLists();
		if (!isEqual(filters, defaultFilters)) {
			setFilters(defaultFilters.map(a => ({ ...a })));
		}
	};

	const resetCommodityGroups = () => {
		const cats = [...categories];
		cats.forEach(c => c['selected'] = false);
		setCategories(cats);
	};

	const resetPriceLists = () => {
		const prices = [...priceLists];
		const { settings } = props.authData;
		if (settings && settings.defaultPriceListId) {
			prices.forEach(c => {
				c['selected'] = c.uuid === settings.defaultPriceListId ? true : false;
			});
		} else {
			prices.forEach(c => c['selected'] = false);
		}
		setPriceLists(prices);
	};

	const isFilterChecked = (filterName: string) => {
		const activeFilter = filters.find((i: { name: string, active: boolean; }) => i.name === filterName);
		return activeFilter ? activeFilter.active : false;
	};

	const handleFilterChange = (filterName: string) => {
		const newFilters = [...filters];
		for (let f of newFilters) {
			if (f.name === filterName) {
				f.active = !f.active;
			}
		}
		setFilters(newFilters);
	};

	const getFilterText = (filterType: string) => {
		let isSelected = false;
		switch (filterType) {
			case 'Groups': isSelected = categories && categories.findIndex(c => c['selected']) !== -1;
				break;
		}
		if (isSelected) {
			return `Showing Products for Selected ${filterType}.`;
		}
		return `Showing Products For All ${filterType}.`;
	};

	const arePriceListsSelected = () => {
		return priceLists && priceLists.findIndex(c => c['selected']) !== -1;
	};

	const sumStocksForLocation = (stocks: { warehouse: string, goods: number; }[], locationOrgUnitIds: string[]) => {
		let sum = 0;
		stocks.forEach(s => {
			if (locationOrgUnitIds.includes(s.warehouse)) {
				sum += s.goods;
			}
		});
		return sum;
	};

	const getSelectedOrgUnit = () => {
		const { locations, settings } = props.authData;
		if (settings) {
			const { selectedLocationId } = settings;
			if (selectedLocationId) {
				const location = locations.find((l: Location) => l.id === selectedLocationId);
				return location ? location.korona_org_unit : null;
			}
		}
		return null;
	};

	const renderStocksForSelectedLocations = (stocks: { warehouse: string, goods: number; }[], props: { authData: any; }) => {
		if (stocks.length === 0) {
			return '---';
		}
		const { locations, settings } = props.authData;
		if (locations && settings) {
			const { selectedLocationId } = settings;
			if (selectedLocationId) {
				const location = locations.find((l: Location) => l.id === selectedLocationId);
				if (location) {
					const sum = sumStocksForLocation(stocks, [location.korona_org_unit]);
					if (isFilterChecked('location')) { // return stocks only for selected location
						return sum;
					}
					else if (locations.length === 1) {
						return sum;
					} else {
						// else return stocks for current location & all locations
						const orgUnitIds = locations.map((l: Location) => l.korona_org_unit);
						const stockAcrossAllLocations = sumStocksForLocation(stocks, orgUnitIds);
						return `${sum} (${stockAcrossAllLocations})`;
					}
				}
			}
		}
		// return 'Select locations to view summary';
		return '';
	};

	const renderCategories = () => {
		return categories.map(c =>
			(<Button
				key={c.uuid}
				variant="contained"
				color={c['selected'] ? 'primary' : 'default'}
				style={{ marginRight: 16 }}
				onClick={() => toggleCategory(c)}>{c.name}</Button>
			));
	};
	const renderPriceListBtns = () => {
		const { settings } = props.authData;
		const nonDefaultPriceLists = priceLists.filter(i => i.uuid !== settings.defaultPriceListId);
		return sortBy(nonDefaultPriceLists, 'name').map(c =>
			(<Button
				key={c.uuid}
				variant="contained"
				color={c['selected'] ? 'primary' : 'default'}
				style={{ marginRight: 16 }}
				onClick={() => togglePriceList(c)}>{c.name}</Button>
			));
	};
	const renderDefaultPriceListBtn = () => {
		const { settings } = props.authData;
		const priceList = priceLists.find(i => i.uuid === settings.defaultPriceListId);
		if (priceList) {
			return (<Button
				key={priceList.uuid}
				variant="contained"
				color={priceList.selected ? 'primary' : 'default'}
				style={{ marginRight: 16 }}
				onClick={() => togglePriceList(priceList)}>{priceList.name}</Button>
			);
		}
		return null;
	};

	const getColumnWidth = (column: string) => {
		const { settings } = props.authData;
		let numOfPriceCols = priceLists.filter(c => c.selected && c.uuid !== settings.defaultPriceListId).length;
		let width = '10%';
		switch (column) {
			case 'product': width = numOfPriceCols === 2 ? '40%' : numOfPriceCols === 3 ? '30%' : '45%';
				break;
			case 'commodity': width = numOfPriceCols === 1 ? '25%' : numOfPriceCols >= 2 ? '20%' : '30%';
				break;
			case 'stocks': width = numOfPriceCols >= 1 ? '10%' : '12.5%';
				break;
			case 'price': width = numOfPriceCols >= 1 ? '10%' : '12.5%';
				break;
		}
		return width;
	};

	const isDefaultPriceListSelected = () => {
		const { settings } = props.authData;
		if (settings && settings.defaultPriceListId) {
			return priceLists.findIndex(c => c.selected && c.uuid === settings.defaultPriceListId) !== -1;
		}
		return false;
	};
	const isDefaultPriceList = () => {
		const { settings } = props.authData;
		if (settings && settings.defaultPriceListId) {
			return priceLists.findIndex(c => c.uuid === settings.defaultPriceListId) !== -1;
		}
		return false;
	};

	const renderDefaultPriceListHeader = () => {
		const { settings } = props.authData;
		if (settings && settings.defaultPriceListId) {
			let priceList = priceLists.find(c => c.selected && c.uuid === settings.defaultPriceListId);
			if (priceList) {
				return (<div
					key={`h${priceList.uuid}`}
					className={classes.headerCell}
					style={{ width: getColumnWidth('price'), textAlign: 'right' }}
				>{`${priceList.name.slice(0, 5)}`}</div>
				);
			}
		}
		return null;
	};

	const renderPriceListHeaders = (defaultPriceListId: string) => {
		let list = priceLists.filter(c => c.selected && c.uuid !== defaultPriceListId);
		if (list.length > 0) {
			list = sortBy(list, 'name');
			return list.map(c =>
				(<div
					key={`h${c.uuid}`}
					className={classes.headerCell}
					style={{ width: getColumnWidth('price'), textAlign: 'right' }}
				>{`${c.name.slice(0, 5)}`}</div>
				));
		}
		return null;
	};

	const getDefaultPriceListId = () => {
		const { settings } = props.authData;
		if (settings && settings.defaultPriceListId) {
			return settings.defaultPriceListId;
		}
		return null;
	};

	const renderDefaultPriceListValues = (product: Product, defaultPriceListId: string, orgUnitId: string) => {
		const defaultPriceList = priceLists.find(c => c.selected && c.uuid === defaultPriceListId);
		if (defaultPriceList) {
			return renderProductPriceByPriceList(product, defaultPriceListId, defaultPriceListId, orgUnitId);
		}
		return null;
	};

	const renderSelectedPriceListValues = (product: Product, defaultPriceListId: string, orgUnitId: string) => {
		let list = priceLists.filter(c => c.selected && c.uuid !== defaultPriceListId);
		list = sortBy(list, 'name');
		return list.map(s => {
			return renderProductPriceByPriceList(product, s.uuid, defaultPriceListId, orgUnitId);
		});
	};

	const renderProductPriceByPriceList = (product: Product, priceListId: string, defaultPriceListId: string, orgUnitId: string) => {
		let price = getProductPriceByPriceListId(product, priceListId, defaultPriceListId, orgUnitId);
		price = price ? price : '---';
		const key = product.uuid + priceListId;
		return (<div key={key} className={classes.cell} style={{ width: getColumnWidth('price'), textAlign: 'right' }}>{price}</div>);
	};

	return (
		<div style={{ backgroundColor: '#F6F7FC', height: '100%', display: 'flex', alignItems: 'center', paddingTop: 10 }}>
			<Container maxWidth="lg" style={{ height: '100%', paddingLeft: 16, paddingRight: 16 }}>
				<Paper style={{ maxWidth: '100%' }}>
					<AppBar position="static" style={{ boxShadow: 'none' }}>
						<Toolbar style={{ paddingLeft: 0, paddingRight: 0 }}>
							<div style={{ width: '81%' }}>
								<div className={classes.search}>
									{/* <div className={classes.searchIcon}>
									<SearchIcon />
								</div> */}
									<InputBase
										placeholder="Search…"
										classes={{
											root: classes.inputRoot,
											input: classes.inputInput,
										}}
										value={queryText}
										inputProps={{ 'aria-label': 'search' }}
										onChange={(e) => setQueryText(e.target.value)}
										onFocus={(e) => KoronaPosInstance.showOsk()}
										onBlur={(e) => KoronaPosInstance.hideOsk()}
									/>
									<Button aria-label="clear"
										style={{
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											boxShadow: 'none'
										}}
										variant="contained"
										color="default"
										onClick={() => clearSearch()}>Clear
								</Button>
								</div>
							</div>
							<div style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								alignItems: 'center',
								width: '40%',
								paddingRight: 16, paddingLeft: 16
							}}>
								{/* <Button color="primary" variant="contained"
									onClick={handleSearch}>Search</Button> */}
								<Button color={isFilterPanelVisible ? 'default' : 'primary'} variant="outlined"
									style={{ backgroundColor: '#fff', marginLeft: 16, marginRight: 16 }}
									onClick={showFilters}>{isFilterPanelVisible ? 'Hide Filters' : 'Show Filters'}</Button>
								<Button variant="outlined"
									style={{ backgroundColor: '#fff' }}
									onClick={resetFilters}>Reset Filters</Button>
							</div>
							{/* <div className={classes.grow} /> */}
						</Toolbar>
					</AppBar>
					{
						isFilterPanelVisible &&
						<div style={{ padding: 16, width: '100%', border: '1px solid #eee' }}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<div style={{ flex: 1 }}>
									<Grid component="label" container alignItems="center" spacing={1}>
										<Grid item>All Products</Grid>
										<Grid item>
											<Switch
												checked={isFilterChecked('in_stock')}
												onChange={(e) => handleFilterChange('in_stock')} name="in_stock" />
										</Grid>
										<Grid item>In Stock</Grid>
									</Grid>
								</div>
								{
									props.authData &&
									props.authData.locations &&
									props.authData.locations.length > 1 &&
									<div style={{ flex: 1 }}>
										<Grid component="label" container alignItems="center" spacing={1}>
											<Grid item>Any Location</Grid>
											<Grid item>
												<Switch
													checked={isFilterChecked('location')}
													onChange={(e) => handleFilterChange('location')}
													name="location"
												/>
											</Grid>
											<Grid item>Current Location</Grid>
										</Grid>
									</div>
								}
							</div>
							{
								categories &&
								categories.length > 0 &&
								<div>
									<div className={classes.label}
										style={{
											paddingBottom: 16,
											paddingTop: 12,
										}}>{`Filter By Groups - ${getFilterText('Groups')}`}</div>
									<div>
										{renderCategories()}
									</div>
								</div>
							}
							{
								priceLists &&
								priceLists.length > 1 &&
								<div style={{ display: 'flex', alignItems: 'center', paddingTop: 24 }}>
									<div className={classes.label}>{`Display Price Groups: `}</div>
									{
										isDefaultPriceList() &&
										<>
											<div className={`${classes.label} ${classes.spacing}`}>{renderDefaultPriceListBtn()}</div>
											<div className={`${classes.label} ${classes.spacingRight}`}>Other:</div>
										</>
									}
									<div className={!isDefaultPriceList() ? classes.spacingLeft : undefined}>{renderPriceListBtns()}</div>
								</div>
							}
						</div>
					}
					<div style={{ minHeight: 400, display: 'flex', flexDirection: 'column', height: '100%' }}>
						<div className={classes.header}>
							<div className={classes.headerCell} style={{ width: getColumnWidth('product') }}>Product</div>
							<div className={classes.headerCell} style={{ width: getColumnWidth('commodity') }}>Commodity Group</div>
							<div className={classes.headerCell} style={{ width: getColumnWidth('stocks'), textAlign: 'center' }}>Stocks</div>
							{
								priceLists &&
								priceLists.length === 0 &&
								<div className={classes.headerCell} style={{ width: getColumnWidth('price'), textAlign: 'right' }}>Price</div>
							}
							{
								isDefaultPriceListSelected() && renderDefaultPriceListHeader()
							}
							{
								arePriceListsSelected() && renderPriceListHeaders(getDefaultPriceListId())
							}
							<div className={classes.headerCell} style={{ width: '5%' }}></div>
						</div>
						{
							!isLoading &&
							pageData &&
							pageData.results.length > 0 &&
							pageData.results.map(p => {
								return (<div className={classes.row} key={p.uuid} onClick={() => navigate(p)}>
									<div className={classes.cell} style={{ width: getColumnWidth('product') }}>{p.name}</div>
									<div className={classes.cell} style={{
										width: getColumnWidth('commodity'),
										whiteSpace: 'nowrap'
									}}>{p.commodityGroup && p.commodityGroup.name ? p.commodityGroup.name : '---'}</div>
									<div className={classes.cell}
										style={{
											width: getColumnWidth('stocks'),
											textAlign: 'center'
										}}>{renderStocksForSelectedLocations(p.product_stocks, props)}</div>
									{
										priceLists &&
										priceLists.length === 0 &&
										<div className={classes.cell} style={{
											width: getColumnWidth('price'),
											textAlign: 'right'
										}}>{getProductPriceForPos(p, getSelectedOrgUnit())}</div>
									}
									{
										isDefaultPriceListSelected && renderDefaultPriceListValues(p, getDefaultPriceListId(), getSelectedOrgUnit())
									}
									{
										arePriceListsSelected && renderSelectedPriceListValues(p, getDefaultPriceListId(), getSelectedOrgUnit())
									}
									<div className={classes.cell} style={{ width: '5%' }}></div>
								</div>);

							})
						}
						{
							isLoading &&
							<div style={{
								width: '100%',
								display: 'flex',
								flex: 1,
								alignItems: 'center',
								justifyContent: 'center',
								flexDirection: 'column',
							}}>
								<CircularProgress color="primary" />
								<div style={{ padding: '16px' }}>Loading...</div>
							</div>
						}
						<div className={classes.header} style={{ justifyContent: 'center', alignItems: 'center' }}>
							<div className={classes.headerCell}>{`Showing ${pageData.results.length} of ${pageData.count} Products`}</div>
						</div>
					</div>
					<div />
				</Paper>
			</Container>
		</div >
	);
};


export default ProductListScreen;