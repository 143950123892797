import { API_CONFIG } from "../../shared/api/config";

const BASE_URL = API_CONFIG.API_BASE_PATH;

export const API_URLS = {
  productByCodeUrl: BASE_URL + 'korona/products/get_by_code/',
  productsUrl: BASE_URL + 'korona/products/',
  commodityGroupsUrl: BASE_URL + 'korona/commodity-groups/',
  priceListsUrl: BASE_URL + 'korona/price-lists/',
  kioskProductsUrl: BASE_URL + 'korona/kiosk-products/',
  supplierProductsUrl: BASE_URL + 'korona/supplier-products/',
  productCountUrl: BASE_URL + 'korona/products/count/',
  productSummaryDataUrl: BASE_URL + 'korona/products/summary/',
  currentOrderSummary: BASE_URL + 'korona/products/current_order_summary/',
  pastOrderSummary: BASE_URL + 'korona/products/past_order_summary/',
  stocksForAllLocations: BASE_URL + 'korona/products/getStocksForAllLocations/',
};

export const getProductsByPage = (requestHeaders: any, page: number, pageSize: number,
  searchTerm: string | null, business_id: number | string, queryTerm?: string, filters?: string) => {
  let URL = `${API_URLS.productsUrl}?web=true&business_ids=${business_id}&page=${page}&page_size=${pageSize}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  } else if (queryTerm) {
    URL = `${URL}&query=${queryTerm}`;
  }
  if (filters) {
    URL += filters;
  }
  return fetch(URL, {
    headers: { ...requestHeaders, },
    method: 'GET'
  });
};
export const getPriceLists = (requestHeaders: RequestHeaders, searchTerm: string | null, business_id: number | string, filters?: string) => {
  let URL = `${API_URLS.priceListsUrl}?business_id=${business_id}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  }
  if (filters) {
    URL += filters;
  }
  return fetch(URL, {
    headers: { ...requestHeaders, },
    method: 'GET'
  });
};

export const getCommodityGroups = (requestHeaders: RequestHeaders, searchTerm: string | null, business_id: number | string, filters?: string) => {
  let URL = `${API_URLS.commodityGroupsUrl}?business_id=${business_id}`;
  searchTerm = searchTerm ? searchTerm.trim() : '';
  if (searchTerm) {
    URL = `${URL}&search=${searchTerm}`;
  }
  if (filters) {
    URL += filters;
  }
  return fetch(URL, {
    headers: { ...requestHeaders, },
    method: 'GET'
  });
};

export interface RequestHeaders {
  Accept: string;
  'Content-Type': string;
  'API-KEY': string;
}

export const getStocksForAllLocations = (requestHeaders: RequestHeaders, business: number | string, uuid: string) => {
  const URL = API_URLS.stocksForAllLocations + `?business_id=${business}&product_uuid=${uuid}`;
  return fetch(URL, {
    headers: { ...requestHeaders, },
    method: 'GET'
  });
};

export const getCurrentOrderSummary = (requestHeaders: RequestHeaders, business_id: number | string, product_uuid: number | string) => {
  let URL = API_URLS.currentOrderSummary + `?business_id=${business_id}&product_uuid=${product_uuid}`;
  return fetch(URL, {
    headers: { ...requestHeaders, },
    method: 'GET'
  });
};