// declare var korona_request: any;
// declare var korona_plugin_api: any;
//@ts-ignore
const korona_plugin_api = window.korona_plugin_api;

// import * as korona_plugin_api from './korona-plugin-api';

class KoronaPosService {
  static instance: KoronaPosService | null = null;

  static getInstance() {
    if (!KoronaPosService.instance) {
      KoronaPosService.instance = new KoronaPosService();
    }
    return KoronaPosService.instance;
  }

  constructor() { }

  backToKorona() {
    korona_plugin_api.backToKorona();
  }

  showOsk() {
    korona_plugin_api.showOsk();
  }
  hideOsk() {
    korona_plugin_api.hideOsk();
  }


}

const KoronaPosInstance = KoronaPosService.getInstance();

export default KoronaPosInstance;