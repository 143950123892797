import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { Product, CurrentOrderSummaryItem } from '../../../products/models';
import { getStocksForAllLocations, getCurrentOrderSummary } from '../../Api';
import { handleError, checkStatus, parseJSON } from '../../../shared/api/core/common';
import _groupBy from 'lodash/groupBy';
import { Dictionary } from 'lodash';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        grow: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(6),
        },
        header: {
            fontSize: '1.2rem',
            backgroundColor: '#eee',
            width: '100%',
            display: 'flex',
            outline: 0,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        row: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            outline: 0,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        section: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 8,
            outline: 0,
        },
        headerCell: {
            padding: '8px',
            fontSize: '0.95rem',
            textAlign: 'left',
            fontWeight: 500,
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
            verticalAlign: 'middle',
        },
        cell: {
            padding: '8px',
            fontSize: '1rem',
            textAlign: 'left',
            fontWeight: 400,
            lineHeight: 1.43,
            letterSpacing: '0.01071em',
            verticalAlign: 'middle',
        },
        top: {
            fontSize: '1.2rem',
        },
        panelRow: {
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: '16px',
        },
        panelCol: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
        },
        panelRowHeader: {
            backgroundColor: '#f5f5f5',
            display: 'flex',
            justifyContent: 'center',
            padding: '8px',
            fontWeight: 500,
            borderBottom: '1px solid rgba(224, 224, 224, 1)',
        },
        panelHeaderText: {
            fontSize: '0.95rem',
        },
        noDataRow: {
            width: '100%',
            padding: 16,
        },
        box: {
            border: '1px solid rgba(224, 224, 224, 1)',
            borderRadius: 4,
        },
        w30: {
            width: '30%',
        },
        w25: {
            width: '25%',
        },
        w20: {
            width: '20%',
        },
        w15: {
            width: '15%',
        },
        textCenter: {
            textAlign: 'center',
        },
    })
);

const ProductDetailScreen = (props: any) => {
    const classes = useStyles();
    const [isLoading, setLoading] = useState(false);
    const { business, authHeaders } = props.authData;
    const history: any = useHistory();
    const { location } = history;
    const [productData, setProductData] = useState({ ...props.history.location.state } as Product);
    const [stocksData, setStocksData] = useState([]);
    const [currentOrderSummary, setCurrentOrderSummary] = useState({
        current_orders: {} as Dictionary<CurrentOrderSummaryItem[]>,
        current_orders_count: 0,
    });
    const prevRoute = location.pathname.slice(0, location.pathname.length - 7);

    useEffect(() => {
        setLoading(true);
        const { uuid, business } = history.location.state as Product;
        // const business = 2;
        // const uuid = "e5c63115-34c8-4a0b-8d82-f5b44b37e6fc";
        if (uuid && business) {
            // getProduct(uuid)
            //   .catch(handleError) // handle network issues
            //   .then(checkStatus)
            //   .then(parseJSON)
            //   .then((data: Product) => {
            //     setLoading(false);
            //     setProductData(data);
            //   })
            //   .catch((error: any) => {
            //     setLoading(false);
            //   });
            getStocksForAllLocations(authHeaders, business, uuid)
                .catch(handleError) // handle network issues
                .then(checkStatus)
                .then(parseJSON)
                .then((res) => {
                    setLoading(false);
                    if (res.data) {
                        setStocksData(res.data);
                    }
                })
                .catch((error: any) => {
                    setLoading(false);
                });
            getOnOrderData(business, uuid);
        } else {
            history.push(prevRoute);
        }
    }, []);

    const getOnOrderData = (businessId: number | string, productId: string) => {
        getCurrentOrderSummary(authHeaders, businessId, productId)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data: CurrentOrderSummaryItem[]) => {
                const groupedCurrentOrders = _groupBy(data, (i) => i.supplier.uuid);
                const summary = {
                    current_orders: groupedCurrentOrders,
                    current_orders_count: data.length,
                };
                setCurrentOrderSummary(summary);
            })
            .catch((error: any) => {});
    };

    const renderProductBaseInfo = () => {
        if (productData) {
            const { name, number } = productData;
            if (name && number) {
                return (
                    <>
                        {` ${number} `}&ndash;{` ${name}`}
                    </>
                );
            }
        }
        return '';
    };

    const renderDate = (date: string | null | undefined) => {
        if (date) {
            return moment(date).format('ll');
        }
        return '----';
    };

    return (
        <div
            style={{
                backgroundColor: '#F6F7FC',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                paddingTop: 16,
            }}
        >
            <Container maxWidth="lg" style={{ height: '100%' }}>
                <Paper style={{ maxWidth: '100%' }}>
                    <AppBar position="static" style={{ boxShadow: 'none' }}>
                        <Toolbar>
                            <Button
                                className={classes.menuButton}
                                onClick={() => history.push(prevRoute)}
                                style={{ backgroundColor: '#fff' }}
                                variant="outlined"
                            >
                                Back
                            </Button>
                            <Typography variant="h6">Product Detail</Typography>
                        </Toolbar>
                    </AppBar>
                    <div
                        style={{
                            minHeight: 400,
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            padding: 24,
                        }}
                    >
                        {!isLoading && (
                            <>
                                <div className={classes.section}>
                                    <Typography variant="h6">
                                        Stock Summary For: {renderProductBaseInfo()}
                                    </Typography>
                                </div>
                                <div className={classes.box}>
                                    <div className={classes.header}>
                                        <div className={`${classes.headerCell} ${classes.w25}`}>
                                            Location
                                        </div>
                                        <div
                                            className={`${classes.headerCell} ${classes.w25} ${
                                                classes.textCenter
                                            }`}
                                        >
                                            On Hand
                                        </div>
                                        <div
                                            className={`${classes.headerCell} ${classes.w25} ${
                                                classes.textCenter
                                            }`}
                                        >
                                            Next Delivery Date
                                        </div>
                                        <div
                                            className={`${classes.headerCell} ${classes.w25} ${
                                                classes.textCenter
                                            }`}
                                        >
                                            Expected Qty
                                        </div>
                                    </div>
                                    {stocksData.map(
                                        (i: {
                                            location_id: number;
                                            location: string;
                                            goods: number;
                                            next_delivery_date: string | null;
                                            expected_qty: null | number;
                                        }) => {
                                            return (
                                                <div className={classes.row} key={i.location_id}>
                                                    <div
                                                        className={`${classes.cell} ${classes.w25}`}
                                                    >
                                                        {i.location}
                                                    </div>
                                                    <div
                                                        className={`${classes.cell} ${
                                                            classes.w25
                                                        } ${classes.textCenter}`}
                                                    >
                                                        {i.goods}
                                                    </div>
                                                    <div
                                                        className={`${classes.cell} ${
                                                            classes.w25
                                                        } ${classes.textCenter}`}
                                                    >
                                                        {renderDate(i.next_delivery_date)}
                                                    </div>
                                                    <div
                                                        className={`${classes.cell} ${
                                                            classes.w25
                                                        } ${classes.textCenter}`}
                                                    >
                                                        {i.expected_qty ? i.expected_qty : '---'}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    )}
                                </div>
                                <div>
                                    <div className={classes.section} style={{ paddingTop: 8 }}>
                                        <Typography variant="h6">On Order</Typography>
                                    </div>
                                    <div className={classes.box}>
                                        {currentOrderSummary.current_orders &&
                                        Object.keys(currentOrderSummary.current_orders).length >
                                            0 ? (
                                            Object.keys(currentOrderSummary.current_orders).map(
                                                (key, index) => {
                                                    if (
                                                        currentOrderSummary.current_orders[key]
                                                            .length > 0
                                                    ) {
                                                        const orders =
                                                            currentOrderSummary.current_orders[key];
                                                        return (
                                                            <div key={key}>
                                                                {index > 0 && <Divider />}
                                                                <div
                                                                    className={
                                                                        classes.panelRowHeader
                                                                    }
                                                                    style={
                                                                        index === 0
                                                                            ? {
                                                                                  borderTopLeftRadius: 4,
                                                                                  borderTopRightRadius: 4,
                                                                              }
                                                                            : undefined
                                                                    }
                                                                >{`Supplier: ${
                                                                    orders[0].supplier.name
                                                                }`}</div>
                                                                <div className={classes.header}>
                                                                    <div
                                                                        className={`${
                                                                            classes.headerCell
                                                                        } ${classes.w30}`}
                                                                    >
                                                                        Location
                                                                    </div>
                                                                    <div
                                                                        className={`${
                                                                            classes.headerCell
                                                                        } ${classes.w20} ${
                                                                            classes.textCenter
                                                                        }`}
                                                                    >
                                                                        Ordered On
                                                                    </div>
                                                                    <div
                                                                        className={`${
                                                                            classes.headerCell
                                                                        } ${classes.w20} ${
                                                                            classes.textCenter
                                                                        }`}
                                                                    >
                                                                        Next Delivery Date
                                                                    </div>
                                                                    <div
                                                                        className={`${
                                                                            classes.headerCell
                                                                        } ${classes.w15} ${
                                                                            classes.textCenter
                                                                        }`}
                                                                    >
                                                                        Ordered Qty
                                                                    </div>
                                                                    <div
                                                                        className={`${
                                                                            classes.headerCell
                                                                        } ${classes.w15} ${
                                                                            classes.textCenter
                                                                        }`}
                                                                    >
                                                                        Pending Qty
                                                                    </div>
                                                                </div>
                                                                <div className={classes.panelCol}>
                                                                    {orders.map(
                                                                        (
                                                                            o: CurrentOrderSummaryItem,
                                                                            index: number
                                                                        ) => (
                                                                            <div
                                                                                className={
                                                                                    classes.row
                                                                                }
                                                                                key={`c${index}`}
                                                                            >
                                                                                <div
                                                                                    className={`${
                                                                                        classes.cell
                                                                                    } ${
                                                                                        classes.w30
                                                                                    }`}
                                                                                >
                                                                                    {o.target}
                                                                                </div>
                                                                                <div
                                                                                    className={`${
                                                                                        classes.cell
                                                                                    } ${
                                                                                        classes.w20
                                                                                    } ${
                                                                                        classes.textCenter
                                                                                    }`}
                                                                                >
                                                                                    {renderDate(
                                                                                        o.bookingTime
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    className={`${
                                                                                        classes.cell
                                                                                    } ${
                                                                                        classes.w20
                                                                                    } ${
                                                                                        classes.textCenter
                                                                                    }`}
                                                                                >
                                                                                    {renderDate(
                                                                                        o.pickUpDate
                                                                                    )}
                                                                                </div>
                                                                                <div
                                                                                    className={`${
                                                                                        classes.cell
                                                                                    } ${
                                                                                        classes.w15
                                                                                    } ${
                                                                                        classes.textCenter
                                                                                    }`}
                                                                                >
                                                                                    {o.ordered_qty}
                                                                                </div>
                                                                                <div
                                                                                    className={`${
                                                                                        classes.cell
                                                                                    } ${
                                                                                        classes.w15
                                                                                    } ${
                                                                                        classes.textCenter
                                                                                    }`}
                                                                                >
                                                                                    {o.pending_qty}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                    return null;
                                                }
                                            )
                                        ) : (
                                            <div className={classes.noDataRow}>
                                                <Typography align="center">
                                                    No Current Orders
                                                </Typography>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {isLoading && (
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    flex: 1,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexDirection: 'column',
                                }}
                            >
                                <CircularProgress color="primary" />
                                <div style={{ padding: '16px' }}>Loading...</div>
                            </div>
                        )}
                    </div>
                </Paper>
            </Container>
        </div>
    );
};

export default ProductDetailScreen;
