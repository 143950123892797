import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import PosLayout from 'components/src/modules/pos/layouts/PosLayout/PosLayout';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#ffb300',
        },
    },
});

const AppNavigator = (props: any) => {
    return (
        <MuiThemeProvider theme={theme}>
            <Router>
                <Switch>
                    <Route path="/ext/:token/:path" component={PosLayout} />
                </Switch>
            </Router>
        </MuiThemeProvider>
    );
};

export default AppNavigator;
