import React, { useEffect, useState } from 'react';
import { Route, useParams } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { verifyToken, LOGIN_HEADERS } from '../../../auth/api';
import { checkStatus, handleError, parseJSON } from '../../../shared/api/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import { ProductDetail, ProductList } from '../../screens';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        mainContent: {
            flexGrow: 1,
            display: 'flex',
        },
        routeContent: {
            flexGrow: 1,
            padding: theme.spacing(3),
            backgroundColor: '#f6f7fC',
            maxWidth: '100%',
            overflow: 'auto',
        },
        toolbar: theme.mixins.toolbar,
    })
);

const PosLayout = (props: any) => {
    const classes = useStyles({});
    const [isAuthorized, setAuthorized] = useState(false);
    const [authorizing, setAuthorizing] = useState(false);
    const [authData, setAuthData] = useState(null);
    const [verificationComplete, setVerificationComplete] = useState(false);
    const { token, path } = useParams<{ token: string, path: string; }>();
    let userLocation = null;

    useEffect(() => {
        setAuthorized(false);
        setAuthorizing(true);
        verifyToken(token)
            .catch(handleError) // handle network issues
            .then(checkStatus)
            .then(parseJSON)
            .then((data) => {
                setAuthorizing(false);
                const authHeaders = { ...LOGIN_HEADERS, 'USER-AUTH-TOKEN': data.auth_token };
                setAuthData({ ...data, authHeaders });
                setAuthorized(true);
                setVerificationComplete(true);
            })
            .catch((errorData: any) => {
                console.log('sign-in error', errorData);
                setAuthorizing(false);
                setAuthorized(false);
                setVerificationComplete(true);
            });
    }, []);

    const getRoute = () => {
        const { history } = props;
        if (history && history.location && history.location.pathname.includes('detail')) {
            return (
                <Route
                    component={() => <ProductDetail authData={authData} history={props.history} />}
                />
            );
        }
        return (
            <Route component={() => <ProductList authData={authData} history={props.history} />} />
        );
    };

    return (
        <div className={classes.root}>
            {verificationComplete &&
                isAuthorized &&
                !authorizing &&
                path == 'products' &&
                getRoute()}
            {authorizing && (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flex: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <CircularProgress color="primary" />
                    <div style={{ padding: '16px' }}>Authorizing...</div>
                </div>
            )}
            {verificationComplete && !isAuthorized && !authorizing && (
                <div style={{ padding: '16px', textAlign: 'center' }}>
                    <Typography variant="h4">403 FORBIDDEN</Typography>
                </div>
            )}
        </div>
    );
};

export default PosLayout;
